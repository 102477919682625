// global.$ = global.jQuery = $;
import Subscription from './subscription';
import ExtranetForms from './extranet-forms';
import BaseDoc from './base-doc';
import Login from './login';
import Calendar from './calendar';
import Alert from "./alert";
import Card from './card';
import Copy from './copy';
import Menus from './menus';
import MenuArticle from './menu-article';
import Scroll from './scroll';
import FrontPage from './front-page';
import Contact from './contact';
import Localiser from './localiser';
import Search from './search';
import Press from './press';
import Sliders from './sliders';
import Share from './share';
import Print from './print';
import MoveBlks from './move-blks';
import autocompleteSearch from './search-gouv';
import mandateCollection from './mandate';
import GlobalSearch from './global-search';
import CommonSearch from "./common-search";
import "./main-autosuggest";

import 'bootstrap';
import 'selectize';
import AutoNumeric from 'autonumeric';
import objectFitImages from 'object-fit-images';
import QuestionsReponses from "./questions_reponses";
import bsCustomFileInput from 'bs-custom-file-input'
import Quiz from "./quiz";
import Newsletter from "./account-newsletter";
import PromiseRewenal from "./promise-rewenal";
import preSubscription from "./pre-subscription";
import YoutubeVideoIframe from "./youtubeVideoIframe";

(function(window){
    window.htmlentities = {
        /**
         * Converts a string to its html characters completely.
         *
         * @param {String} str String with unescaped HTML characters
         **/
        encode : function(str) {
            var buf = [];

            for (var i=str.length-1;i>=0;i--) {
                buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
            }

            return buf.join('');
        },
        /**
         * Converts an html characterSet into its original character.
         *
         * @param {String} str htmlSet entities
         **/
        decode : function(str) {
            return str.replace(/&#(\d+);/g, function(match, dec) {
                return String.fromCharCode(dec);
            });
        }
    };
})(window);

(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? factory() :
        typeof define === 'function' && define.amd ? define(factory) :
            (factory());
}(this, (function () {
    'use strict';

    // Bootstrap validation form
    window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        const forms = document.getElementsByClassName('needs-validation');

        // Loop over them and prevent submission
        let validation = Array.prototype.filter.call(forms, function(form) {
            form.setAttribute('novalidate', true);

            form.addEventListener('submit', function(event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                const disableOnSubmit = form.querySelector('.disable-on-sumbit');
                if (disableOnSubmit) {
                    disableOnSubmit.classList.add('disabled');
                    disableOnSubmit.setAttribute('disabled', 'true');
                }

                const checkboxes = form.querySelectorAll('.checkboxtoenable');
                Array.prototype.filter.call(checkboxes, function(checkbox) {
                    checkbox.removeAttribute('disabled');
                });

                const dismiss = form.querySelector('.btn-dismiss');

                if (dismiss && form.checkValidity()) {
                    const parentModal = event.target.closest('.modal');
                    $('#'+parentModal.id).modal('hide');
                }

                form.classList.add('was-validated');
            }, false);
        });


        Login.init();
        Calendar.init();
        Subscription.init();
        ExtranetForms.init();
        CommonSearch.init();
        Newsletter.init();
        PromiseRewenal.init();


        const body = document.querySelector('body');

        if (body.classList.contains('archive')) {
            BaseDoc.init();
        }

        if (body.classList.contains('search-results')) {
            GlobalSearch.init();
        }

        autocompleteSearch('https://api-adresse.data.gouv.fr/search/?q=');

        new Alert();
        new Menus();
        new MenuArticle();
        new Card();
        new Copy();
        new Scroll();
        new FrontPage();
        new Contact();
        new QuestionsReponses();
        new Localiser();
        new Search();
        new Press();
        new Sliders();
        new Share();
        new Print();
        new Quiz();
        new YoutubeVideoIframe();

        // Mandate services
        mandateCollection();

        // Pre subscription
        preSubscription();

        if (window.matchMedia("(min-width: 1024px)").matches) {
            new MoveBlks();
        }

        const anInputs = document.querySelectorAll('.numberFormat');

        if (anInputs.length > 0) {
            const autoNumericOptionsEuro = {
                digitGroupSeparator        : ' ',
                decimalCharacter           : ',',
                decimalCharacterAlternative: '.',
                // currencySymbol             : '\u202f€',
                currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.suffix,
                roundingMethod             : AutoNumeric.options.roundingMethod.halfUpSymmetric,
                decimalPlaces              : 0,
                unformatOnSubmit           : true
            };

            Array.prototype.filter.call(anInputs, function(anInput) {
                new AutoNumeric(anInput, autoNumericOptionsEuro);
            });

        }

        // Polyfill object-fit/object-position on <img>
        objectFitImages();

        if (window.matchMedia("(min-width: 768px)").matches) {
            $('[data-toggle="popover"]').popover({
                trigger: 'hover'
            });
        }

    }, false);

    $('select:not(".qr-filter-theme-select"):not(".qr-filter-month-select"):not(".region-select"):not(".prevent-selectize-select select"):not("#establishment"):not("#etablissement_simulation")').selectize();

    $('.region-select').selectize({
        render: {
            item: function (item, escape) {
                return '' +
                    '<div class="option">' +
                    '   <i class="icon-map-pin mr-1"></i>' +
                    '   <span class="name">' + escape(item.text) + '</span>' +
                    '</div>';
            },
        }
    });

    const url = new URL(window.location.href);
    const hash = url.hash;

    if (hash) {
        $('html, body').animate({
            scrollTop: $(hash).offset().top - 200
        }, 800);
    }
})));

$(document).ready(function () {
    bsCustomFileInput.init()
})
